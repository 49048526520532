import React from "react";
import './style.css';

function Paragraph() {
    return(
        <div className="paragraph">
            <div className="frase">"CONTE COM NOSSA EXPERTISE EM SUPORTE TÉCNICO, SOLUÇÕES DE TI E ASSISTÊNCIA ADMINISTRATIVA, GARANTINDO PRATICIDADE E EFICIÊNCIA PARA O SEU DIA A DIA."</div>
            
            <p>- Equipe Mello4All</p>
        </div>
    );
}

export default Paragraph;