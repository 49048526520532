import React from 'react';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './componentes/home';
import './App.css';

function App() {
    return (
        <div className='App'>
            <Home />
        </div>
    );
}

export default App;
